import firebase from 'firebase/app';
import { FormattedIdin, Idin } from '@/models/identification-requests/idin';
import { Pescheck } from '@/models/identification-requests/pescheck';
import {
  BusinessIdentification,
  PrivateIdentification,
} from '@/models/identification-requests/IdentificationRequest';
import { QuestionnaireAnswers } from '@/models/users/Questionnaire';

/**
 * Defining the different roles for managers.
 */
export enum UserRole {
  Superadmin = 'superadmin',
  Admin = 'admin',
  Editor = 'editor',
}

// Workaround for the importing problem as JS object.
export const roles = Object.values(UserRole);

/**
 * This is the banhammer interface. Handles the complete disability
 * of the user to interact with the web application.
 */
export enum UserStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

/**
 * Representing our current KYC methods excluding Pescheck.
 */
export enum KYCMethods {
  Idin = 'idin',
  Private = 'private',
  Business = 'business',
}

/**
 * Defining the user's tier and what's his/her account privileges/data.
 */
export enum UserTier {
  Account = 'account',
  Investor = 'investor',
  Trader = 'trader',
}

/**
 * Main User interface. At this form, the user is on the 'account' tier since it does not have advanced
 * to any other higher tier.
 */
export interface User {
  bankAccount?: string;
  createdDateTime: firebase.firestore.Timestamp;
  customId: number;
  email: string;
  id?: string;
  /**
   * The 'identificationRequest' indicates that there is an iR but not approved yet / rejected
   */
  identificationRequest?: firebase.firestore.DocumentReference | PrivateIdentification | BusinessIdentification;
  /**
   * The 'idin' property here indicates that the user could have a failed / not completed idin transaction.
   */
  idin?: firebase.firestore.DocumentReference | Idin;
  pescheck?: firebase.firestore.DocumentReference | Pescheck;
  status: UserStatus;
  statusMessage?: string;
  tier: UserTier;
  questionnaire?: QuestionnaireAnswers;
  updatedDateTime: firebase.firestore.Timestamp;
  usedBankAccounts?: { value: string, lastUsed: firebase.firestore.Timestamp }[];
}

/**
 * Basic User plus idin data transformed into legible properties.
 */
export interface IdinInvestor extends Omit<User, 'identificationRequest'>, FormattedIdin {
  kycMethod: KYCMethods.Idin;
  tier: UserTier.Investor;
  idin: firebase.firestore.DocumentReference | Idin;
}

/**
 * Basic User plus the private identification data.
 */
export interface PrivateInvestor extends Omit<User, 'idin' | 'bankAccount'>, Omit<PrivateIdentification, 'status'> {
  kycMethod: KYCMethods.Private;
  tier: UserTier.Investor;
  identificationRequest?: firebase.firestore.DocumentReference | PrivateIdentification;
}

/**
 * Basic User plus the business identification data.
 */
export interface BusinessInvestor extends Omit<User, 'idin' | 'bankAccount'>, Omit<BusinessIdentification, 'status'> {
  kycMethod: KYCMethods.Business;
  tier: UserTier.Investor;
  identificationRequest?: firebase.firestore.DocumentReference | BusinessIdentification;
}

/**
 * Discrimination union between all the investor types. The 'kycMethod' field makes the difference.
 * At this point the User is an Investor and has the `tier === UserTier.Investor`.
 * todo it would be better if this would be correctly typed ie using generics and conditional types
 */
export type Investor = IdinInvestor | PrivateInvestor | BusinessInvestor;

/**
 * Type guard to check if someone is an investor.
 * @param userOrInvestor basic User or Investor interfaces.
 */
export const isInvestor = (userOrInvestor: User | Investor): userOrInvestor is Investor => userOrInvestor.tier === UserTier.Investor;

/**
 * Data for a bank account change request.
 */
export interface BankAccountChange {
  id?: string;
  bankAccount: string;
  changed: boolean;
  previousBankAccount: string;
  userId: string;
  createdDateTime: firebase.firestore.Timestamp;
}

/**
 * Data for a bank account change request.
 */
export interface DataChangeRequest {
  type: 'name' | 'bankAccount';
  investor: Investor;
  status: 'pending' | 'approved' | 'rejected';
  previousData: { name: string; surname: string; } | { bankAccount: string; }
  newData: { name: string; surname: string; } | { bankAccount: string; }
}

export type BusinessIdentificationImages = keyof Pick<BusinessInvestor, 'passport' | 'kvkImage'>;
