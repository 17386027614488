// https://github.com/benmosher/eslint-plugin-import/issues/2065
/* eslint-disable */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
/* eslint-disable */

const bloqifyConfig = {
  apiKey: process.env.VUE_APP_BLOQIFY_FIREBASE_API_KEY as string,
  authDomain: process.env.VUE_APP_BLOQIFY_FIREBASE_AUTH_DOMAIN as string,
  databaseURL: process.env.VUE_APP_BLOQIFY_FIREBASE_DATABASE_URL as string,
  projectId: process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID as string,
  storageBucket: process.env.VUE_APP_BLOQIFY_FIREBASE_STORAGE_BUCKET as string,
  messagingSenderId: parseInt(process.env.VUE_APP_BLOQIFY_FIREBASE_MESSAGING_SENDER_ID as string, 10),
};
type BloqifyConfig = { [key in keyof typeof bloqifyConfig] : string};

const bloqify = firebase.initializeApp(bloqifyConfig);

const bloqifyAuth = bloqify.auth();
const bloqifyStorage = bloqify.storage();
const bloqifyFirestore = bloqify.firestore();

// Special region for local test
const bloqifyFunctions: firebase.functions.Functions = bloqify.functions(
  process.env.VUE_APP_BLOQIFY_FIREBASE_CLOUD_FUNCTIONS_REGION,
);

// Emulator for local tests
if (process.env.NODE_ENV === 'development') {
  bloqifyFunctions.useEmulator('localhost', 5001);
  // bloqifyFirestore.useEmulator('localhost', 8081);
}

export {
  bloqify,
  BloqifyConfig,
  bloqifyFirestore,
  bloqifyAuth,
  bloqifyFunctions,
  bloqifyStorage,
  firebase,
};
